import React from 'react';

import { graphql } from 'gatsby';

import Footer from '../components/Footer/Footer';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import styled from 'styled-components';
import SEO from '../components/SEO';
import BlogList from '../components/BlogList/BlogList';
import Header from '../components/Header/header';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export default ({ data }) => {
  return (
    <>
      <SEO title="Latest Articles"></SEO>
      <Header />
      <MaxWidthWrapper>
        <BodyWrapper>
          <BlogList posts={data.allMdx.edges} />
        </BodyWrapper>
      </MaxWidthWrapper>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            published
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
